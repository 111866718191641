import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import  axios  from 'axios';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Form,
  Label,
  Row,
  Col,
} from "reactstrap";

function Register() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');

  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(''); // State to store the index of the selected question
  const [securityAnswer, setSecurityAnswer] = useState('');
  const predefinedQuestions = [
    "What was the name of the first school you remember attending?",
    "What was the name of your first stuffed toy?",
    "In which universe did you meet your significant other?",
  ];


  
  const validateSecurityQuestion = (index) => {
    // Check if the index is a valid number and within the range of predefined questions
    return index !== '' && predefinedQuestions[index] !== undefined;
  };
  const handlePhoneChange = (newValue) => {
    setPhone(newValue);
  };
  const validateFullName = (name) => {
    const regex = /^[a-zA-Z\sáéíóúÁÉÍÓÚñÑüÜ]+$/;
    return regex.test(name);
  };
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  
  const validateUsername = (username) => {
    const regex = /^[a-zA-Z0-9_-]+$/;
    return regex.test(username);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);  // Reset error message
    setIsLoading(true);

    if (!validateFullName(fullName)) {
      setError("Full name must contain only letters and spaces.");
      setIsLoading(false);
      return;
    }
  
    if (!validateEmail(email)) {
      setError("Invalid email format");
      setIsLoading(false);
      return;
    }
  
    if (!validateUsername(username)) {
      setError("Username must contain only letters, numbers, underscores, or hyphens.");
      setIsLoading(false);
      return;
    }
    // validate the phone numb - client side
    if (!isValidPhoneNumber(phone)) {
      setError("Invalid phone number format");
      setIsLoading(false);
      return;
    }
    if (!validateSecurityQuestion(selectedQuestionIndex)) {
      setError("Please select a valid security question.");
      setIsLoading(false);
      return;
    }
    

    const userData = {
      username,
      email,
      // public_key: jwkPublicKey,
      phonenumber: phone,
      first_name: fullName.split(' ')[0],
      last_name: fullName.split(' ').slice(1).join(' '),
      selected_question: predefinedQuestions[selectedQuestionIndex],
      answer: securityAnswer,
    };
    try {
      const response = await axios.post(`${API_BASE_URL}/register/`, userData);

      if (response.status === 201) {
          setIsLoading(false); 
          const queryParams = new URLSearchParams({
            verifyEmail: 'true',
            email: email
        });
        navigate(`/login?${queryParams.toString()}`); // Redirect on success with query params
      
      } else {
        const result = response.data;
        setError(result.error || 'Registration failed');
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setError('Registration Failed: ' + error.response.data.error);
      } else {
        setError('An Error Occurred During Registration: ' + error.message);
      }
      setIsLoading(false);
    }
    };

  return (
    <div className="content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
<div style={{ width: '1000px', height: '400px' }}> {/* Adjust the width and height as needed */}
      <Row className="justify-content-center">
        <Col md="6">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Register</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="fullName">Full Name</Label>
                  <Input
                    type="text"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    placeholder="Enter full name"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter email"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter username"
                    required
                  />
                </FormGroup>
                <FormGroup>
                    <Label for="securityQuestion">Security Question</Label>
                    <Input
                      type="select"
                      name="securityQuestion"
                      id="securityQuestion"
                      value={selectedQuestionIndex}
                      onChange={(e) => setSelectedQuestionIndex(e.target.value)}
                      required
                    >
                      <option value="">Select a question...</option>
                      {predefinedQuestions.map((question, index) => (
                        <option key={index} value={index}>{question}</option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="securityAnswer">Security Answer</Label>
                    <Input
                      type="text"
                      value={securityAnswer}
                      onChange={(e) => setSecurityAnswer(e.target.value)}
                      placeholder="Enter security answer"
                      required
                    />
                  </FormGroup>
                <PhoneInput
                  international
                  defaultCountry="BE"
                  value={phone}
                  onChange={setPhone}
                  error={Boolean(error && !isValidPhoneNumber(phone))}
                  className="form-control" // Use form-control for Bootstrap styling
                />
                {error && <div className="alert alert-danger">{error}</div>}
                   <Button type="submit" color="primary" disabled={isLoading}>
                        {isLoading ? 'Registering...' : 'Register'}
                      </Button>
                
                <Button>
                  <Link to="/login">Go To Login Page</Link>
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
    </div>
  );
}

export default Register;
