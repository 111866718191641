import { useState, useEffect, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext.js';
import axios from 'axios';
const LoadingSpinner = () => {
    const spinnerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    };
  
    const spinnerCircleStyle = {
      border: '5px solid #f3f3f3',
      borderTop: '5px solid #3498db',
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      animation: 'spin 2s linear infinite',
    };
  
    const keyframesStyle = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;
  
    return (
      <div style={spinnerStyle}>
        <style>
          {keyframesStyle}
        </style>
        <div style={spinnerCircleStyle}></div>
      </div>
    );
  };
const PrivateRoute = ({ children }) => {


    
    const { user, setUser  , axiosInstance  } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true); // New state to track loading status

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const userInfoResponse = await axiosInstance.get('https://api.borrowendlessly.be/api/login/info');
                if (userInfoResponse.status === 200) {
                    setUser(userInfoResponse.data);
                }
            } catch (error) {
                console.error('Error checking auth status: User Not authorized');
            } finally {
                setIsLoading(false); // Set loading to false after the check is complete
            }
        };  

        if (!user) {
            checkAuthStatus();
        } else {
            setIsLoading(false); // If user is already set, no need to load
        }
    }, [user, setUser]);

    if (isLoading) {
        return <LoadingSpinner />; // Or any other loading indicator
    }

    return !user ? <Navigate to='/login'/> : children;
}

export default PrivateRoute;
