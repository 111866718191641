import React, { useEffect  } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from "reactstrap"; // Import the missing components

function EmailVerificationSuccess() {
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to the login page after 3 seconds
        const timer = setTimeout(() => {
            navigate('/login');
        }, 5000);

        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
    }, [navigate]);
        

return (
    <div className="content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div style={{ width: '400px', height: '400px' }}>
            <Card>
                <CardHeader>
                <CardTitle tag="h4">Email Verified Successfully!</CardTitle>
                </CardHeader>
                <CardBody>
                <div className="text-center mt-3">
                <p>You will be redirected to the login page shortly.</p>
                </div>
                        <div className="text-center mt-3">
                            <Link to="/login">Login to your account</Link>
                        </div>
              
                </CardBody>
            </Card>
        </div>
    </div>
);
}
export default EmailVerificationSuccess;


