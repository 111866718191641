import React, { useState, useContext , useEffect  } from 'react';
import { Link , useLocation} from "react-router-dom";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';
import AuthContext from '../contexts/AuthContext.js';
import ReCAPTCHA from "react-google-recaptcha";

function OTP() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  let {loginUser} = useContext(AuthContext);
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const [info, setInfo] = useState('');
  const location = useLocation();

  const [showSecurityQuestion, setShowSecurityQuestion] = useState(false); // Initialize state
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const securityQuestions = [
    "What was the name of the first school you remember attending?",
    "What was the name of your first stuffed toy?",
    "In which universe did you meet your significant other?",
  ];
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };
  const secTok = getQueryParam('sec_tok');

  useEffect(() => {
    // Function to parse query parameters

    if (secTok) {
      setShowSecurityQuestion(true);
    } else {
      setShowSecurityQuestion(false);
    }

    // Clear the info message after a certain time
    const timer = setTimeout(() => setInfo(''), 5000);
    return () => clearTimeout(timer);
  }, [location, info]);
  const verifyOTP = async (e) => {
    
    e.preventDefault();
    setInfo('An OTP has been sent to your email. Please check your inbox.');

    setError('');
    if (!/^[A-Z0-9]{32}$/.test(otp)) {
      setError('Invalid OTP format');
      return;
    }
    const response = await fetch(`${API_BASE_URL}/verify-recaptcha/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ recaptcha: captchaValue })
        });
        const data = await response.json();
        if (data.success) {
          await loginUser(otp , secTok, selectedQuestion , answer,   setError);
        } else {
          setError('Please verify you are not a robot !');
        }
      

  };
  const onChangeCaptcha = (value) => {
    setCaptchaValue(value);
  };


  

  return (
    <div className="content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ width: '400px', height: '400px' }}>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Verify OTP</CardTitle>
          </CardHeader>
          <CardBody>
          {info &&  <div className="alert alert-info" role="alert">{info}</div>}
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
            <Form onSubmit={verifyOTP}>
              <FormGroup>
                <Label for="otp">OTP</Label>
                <Input 
                  type="text" 
                  value={otp} 
                  onChange={(e) => setOtp(e.target.value)} 
                  placeholder="Enter OTP" 
                  required
                />
              </FormGroup>
              {showSecurityQuestion && (
                <>
                  <FormGroup>
                    <Label for="selectedQuestion">Select a Security Question</Label>
                    <Input
                      type="select"
                      value={selectedQuestion}
                      onChange={(e) => setSelectedQuestion(e.target.value)}
                      required
                    >
                      <option value="" disabled>Select a question</option>
                      {securityQuestions.map((question, index) => (
                        <option key={index} value={question}>{question}</option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="answer">Your Answer</Label>
                    <Input
                      type="text"
                      value={answer}
                      onChange={(e) => setAnswer(e.target.value)}
                      placeholder="Enter your answer"
                      required
                    />
                  </FormGroup>
                </>
                )}
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={onChangeCaptcha}
            />
              <Button type="submit" color="primary" className="btn-block">Verify OTP</Button>
            </Form>
            <div className="text-center mt-3">
                                <Link to="/login">Back To Login</Link>
                            </div>
          </CardBody>
          
        </Card>
      </div>
    </div>
  );
};

export default OTP;
