import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../contexts/AuthContext.js'; // Adjust the import path as needed
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle , faKey } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
function UserProfile() {
  const [profile, setProfile] = useState({});
  const [error, setError] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newfirstname, setNewFirstName] = useState('');
  
  const [newlastname, setNewLastName] = useState('');
  const [infoMessage, setInfoMessage] = useState('');

  const [showOtpForm, setShowOtpForm] = useState(false);
  const { axiosInstance } = useContext(AuthContext);
  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
     
      const response = await axiosInstance.get('/profile');
  
      if (response.status === 200) {
        setProfile(response.data);
      } else {
          setError("Sorry, we're having trouble loading your profile information. Please try again later.");
      }
    } catch (error) {
      if (error.response) {
        setError("Sorry, we couldn't retrieve your profile. Please try again later.");
      } else if (error.request) {
        // The request was made but no response was received
        setError("Network error. Please check your internet connection and try again.");
      } else {
        setError("An unexpected error occurred. Please try again later.");
      }
    }
  };
  const [copied, setCopied] = useState(false);

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(profile.unique_code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    });
  };
  const invitationCodeStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#626ee3', // Adjusted for dark blue theme
    color: '#ffffff', // White text for better contrast
    padding: '10px',
    borderRadius: '8px',
    margin: '10px 0',
    position: 'relative', // For absolute positioning of 'Copied' message
  };

  const invitationCodeIconStyle = {
    color: '#a2d2ff', // Lighter blue icon for visibility on dark blue
    marginRight: '10px',
  };

  const codeStyle = {
    marginLeft: '5px',
    fontWeight: 'bold',
    color: '#000000', // Black text for the code
    backgroundColor: '#626ee3', // Lighter blue background for code
    padding: '3px 8px',
    borderRadius: '4px',
    cursor: 'pointer', // Indicates it's clickable
  };

  const copiedMsgStyle = {
    position: 'absolute', // Absolute positioning
    right: '20px', // Adjust as needed based on your layout
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#28a745', // Bootstrap's 'success' green
    color: '#ffffff',
    padding: '2px 10px',
    borderRadius: '4px',
    fontSize: '0.9em',
  };
  const regenerateCode = async () => {
    try {
      // API call to regenerate the invitation code
      const response = await axiosInstance.post('/profile/regenerate-invitation-code');
      if (response.status === 200) {
        // Update the profile state with the new code
        setProfile({ ...profile, unique_code: response.data.new_unique_code });
        setInfoMessage("Your new invitation code has been generated successfully!");
        } else {
        setError("Unable to regenerate the invitation code. Please try again later.");
      }
    } catch (error) {
      // Handle errors, such as displaying a message to the user
      setError("An error occurred while trying to regenerate the invitation code.");
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    
    // Create the payload with updated profile data
    const updatedProfile = {
      first_name: newfirstname,
      last_name: newlastname
      // phone_number: profile.phone_number // Ensure this matches the field name expected by the Django view
    };
  
    try {
      const response = await axiosInstance.post('profile/edit/', updatedProfile);
      if (response.status === 200) {
        await getProfile(); 
        setProfile({ ...profile});
        setError('');
        setInfoMessage("Your profile has been updated successfully!");
      }
    } catch (error) {
      if (error.response) {
        // Handle errors returned from the server
        setError('');
        setInfoMessage('');
        setError(error.response.data.error);
      } else {
        // Handle other errors, such as network issues
        setInfoMessage('');
        setError("An error occurred while updating the profile.");
      }
    }
  };
  

  const handleEmailChange = async (e) => {
    e.preventDefault();
  
    // Ensure new email is provided
    if (!newEmail || newEmail === profile.email) {
      setError('Please provide a new email address different from the current one.');
      return;
    }
  
    try {
      const response = await axiosInstance.post('profile/change-email-request/');
      
      if (response.status === 200) {
        // Assuming the backend sends an OTP to the user's current email
        setInfoMessage("An OTP has been sent to your new email address. Please follow the instructions to confirm the change.");
        setShowOtpForm(true);
        }
    } catch (error) {
      if (error.response) {
        // Handle errors returned from the server
        setError(error.response.data.error);
      } else {
        // Handle other errors, such as network issues
        setError("An error occurred while trying to change the email.");
      }
    }
  };

  const handleOtpAndEmailSubmit = async (e) => {
    e.preventDefault();
  
    if (!otp || !newEmail) {
      setError('Both OTP and new email are required.');
      return;
    }
  
    try {
      const response = await axiosInstance.post('profile/edit/update-email/', { otp: otp, new_email: newEmail });
  
      if (response.status === 200) {
        console.log(response.data.message);
        setProfile({ ...profile, email: newEmail, email_verified: response.data.email_verified });
        setInfoMessage("An OTP has been sent to your new email address. Please follow the instructions to confirm the change.");

        getProfile();
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.error);
      } else {
        setError("An error occurred while trying to update the email.");
      }
    }
  };
  
  const resendVerificationEmail = async () => {
    try {
      // Assuming the email to be verified is the user's current email in the profile state
      const response = await axiosInstance.post('resend-verification-email/', { email: profile.email });
  
      if (response.status === 200) {
        setInfoMessage("A new activation link has been sent. Please follow the instructions to verify you email address.");

      }
    } catch (error) {
      if (error.response) {
        // Handle errors returned from the server
        setError(error.response.data.error);
      } else {
        // Handle other errors, such as network issues
        setError("An error occurred while trying to resend the verification email.");
      }
    }
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="10">
            <Card>
              <CardHeader>
                <h5 className="title">Edit Profile</h5>
              </CardHeader>
              <CardBody>
                {infoMessage && <div className="alert alert-info" role="alert">{infoMessage}</div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <Form onSubmit={handleProfileUpdate}>
                  <div style={invitationCodeStyle}>
                    <i style={invitationCodeIconStyle} className="tim-icons icon-single-02" />
                    <span>Your Invitation Code is:</span>
                    <span style={codeStyle} onClick={copyCodeToClipboard}>
                      {profile.unique_code}
                    </span>
                    {copied && <span style={copiedMsgStyle}>Copied!</span>}
                  </div>
                  <Button className="btn-fill" color="primary" onClick={regenerateCode}>
                    Regenerate
                  </Button>
                  <Row>
                  
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          placeholder={profile.first_name}
                          type="text"
                          value={newfirstname}
                          onChange={(e) => setNewFirstName(e.target.value)}
                        />
                      </FormGroup>
                      </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          placeholder={profile.last_name}
                          type="text"
                          value={newlastname}
                          onChange={(e) => setNewLastName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button className="btn-fill" color="primary" type="submit">
                    Save
                  </Button>
                </Form>
                <Form onSubmit={handleEmailChange}>
                  <FormGroup>
                    <label>New Email Address</label>
                    <Input
                      type="email"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                      placeholder={profile.email}
                    />
                    <Button type="submit" color="primary">Change Email</Button>
                  </FormGroup>
                </Form>
                {showOtpForm && (
                    <Form onSubmit={handleOtpAndEmailSubmit}>
                      <FormGroup>
                        <label>OTP</label>
                        <Input
                          type="text"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          placeholder="Enter OTP"
                        />
                        <Button type="submit" color="primary">Verify OTP</Button>
                      </FormGroup>
                    </Form>
                  )}
                {!profile.email_verified && (
                  <div>
                    <p>Your email is not verified. You cannot create or modify expenses.</p>
                    <Button onClick={resendVerificationEmail}>Resend Verification Email</Button>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserProfile;

