import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
const AuthContext = createContext();
export default AuthContext;
export const AuthProvider = ({ children }) => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = "X-CSRFToken"
    axios.defaults.withCredentials = true;
    const [user, setUser] = useState(null);

    const navigate = useNavigate();
   

    const refreshTokens = async () => {
      try {
          const response = await axiosInstance.post('token/refresh/');
          if (response.status === 200) {
              const userInfoResponse = await axiosInstance.get('login/info/');
              setUser(userInfoResponse.data);
          }
      } catch (error) {
          console.log(error);
      }
  };
    const getCsrfToken = () => {
        // Assuming the CSRF token is stored in a cookie named 'csrftoken'
        return document.cookie.split('; ')
            .find(row => row.startsWith('csrftoken='))
            ?.split('=')[1];
    }
    const axiosInstance = axios.create({

        baseURL: `${API_BASE_URL}/`,
        withCredentials: true , 
        headers: {
            'X-CSRFToken': getCsrfToken()  
        }
    });


    axiosInstance.interceptors.response.use(
      response => response,
      async error => {
          const originalRequest = error.config;
          if (error.response.status === 401 && !originalRequest._retry) {
              originalRequest._retry = true;
              await refreshTokens();
              return axiosInstance(originalRequest);
          }
          return Promise.reject(error);
      }
  );

    const OTPUser = async (username, setError) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/login/request/`, { username });
            return response;
        } catch (error) {
            setError('Login failed: ' + error.message);
            return null; // Return null to indicate an error occurred
        }
    };
    const loginUser = async (otp, secTok, selectedQuestion, answer, setError) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/login/verify/`, {
                otp,
                sec_tok: secTok,
                question: selectedQuestion,
                answer
            }, { withCredentials: true });
            const data = response.data;
            if (response.status === 200 && data.Success === "Logged in successfully") {
                const userInfoResponse = await axios.get(`${API_BASE_URL}/login/info`, { withCredentials: true });
                setUser(userInfoResponse.data);
                navigate('/user/dashboard');
            } else {
                setError('OTP verification failed');
            }
        } catch (error) {
            setError('OTP verification error: ' + error.message);
        }
    };


    let logoutUser = async () => {
      try {
          await axiosInstance.get('logout/');
          setUser(null);
          navigate('/login');
      } catch (error) {
          console.error('Logout error:', error);
      }
  };

    const isTokenExpired = (token) => {
        const decodedToken = jwtDecode(token);
        return decodedToken.exp * 1000 < Date.now();
    };

    const checkAuthStatus = async () => {
      try {
          const userInfoResponse = await axios.get(`${API_BASE_URL}/login/info`, { withCredentials: true });
          console.log('doing checkauthstatus in authcontext ',userInfoResponse.data.username)
          if (userInfoResponse.data.username) {
            setUser({
                id: userInfoResponse.data.id,
                username: userInfoResponse.data.username
            });
              console.log("user in user variable is : ", user)
          }
      } catch (error) {
          console.error('Error checking auth status:', error);
      }
  };
    let contextData = {
        user: user,
        setUser: setUser,
        OTPUser: OTPUser,
        loginUser: loginUser,
        checkAuthStatus: checkAuthStatus,
        logoutUser: logoutUser,
        axiosInstance: axiosInstance
    };
    
    

    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    );
};