
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import AdminLayout from "./layouts/User/User.js";

import "./assets/scss/black-dashboard-react.scss";
import "./assets/demo/demo.css";
import "./assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Login from "./views/Login.js";
import Register from "./views/Register.js";
import EmailVerificationSuccess from "./views/emailverification.js"
import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import PrivateRoute from './utils/PrivateRoute'
import { AuthProvider } from './contexts/AuthContext.js'
import OTP from "./views/otp.js"
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <BrowserRouter>
      <AuthProvider>
        <Routes>
          
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/email-verified" element={<EmailVerificationSuccess />} />
          <Route path="/otp-user" element={<OTP />} />

          <Route path="/" element={<PrivateRoute><AdminLayout /></PrivateRoute>} />
          <Route path="/user/*" element={<PrivateRoute><AdminLayout /></PrivateRoute>} />
        </Routes>
        </AuthProvider>
      </BrowserRouter>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>
);
