import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import AuthContext from '../contexts/AuthContext.js';
import axios from 'axios';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Form,
  Label,
} from "reactstrap";

function Login() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [error, setError] = useState('');
    const { OTPUser } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [infoMessage, setInfoMessage] = useState('');
    const [resendDisabled, setResendDisabled] = useState(true);
    const [emailToVerify, setEmailToVerify] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailQueryParam = queryParams.get('email');
        setEmailToVerify(emailQueryParam || '');

        if (queryParams.get("verifyEmail") === "true") {
            setInfoMessage("Please verify your email. A verification link has been sent to your email address.");
            setTimeout(() => setResendDisabled(false), 5000); // Enable resend button after 5 seconds
        }
    }, [location]);

    const handleResendVerification = async () => {
        if (!emailToVerify) {
            setError("No email found to resend verification.");
            return;
        }

        try {
            await axios.post(`${API_BASE_URL}/resend-verification-email/`, { email: emailToVerify });
            setInfoMessage("A new verification email has been sent. Please check your email.");
            setResendDisabled(true); // Disable the resend button for 10 minutes
            setTimeout(() => setResendDisabled(false), 600000); // 10 minutes
        } catch (error) {
            setError("Failed to resend verification email.");
            console.error("Error in resending email: ", error);
        }
    };

    const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        const response = await OTPUser(username, setError);
        console.log(response.data)
        if (response && response.data && response.data.redirect_url) {
            // If there is a redirect URL, redirect the user
            console.log("redirecting to url", response.data.redirect_url)
            navigate(response.data.redirect_url);
        } else {
            // Handle other responses appropriately
            setInfoMessage("If the account is valid, we will send a login link to your email.");
            navigate('/otp-user');
        }
    } catch (err) {
        setError("Login failed. Please try again.");
        console.error("Login Error: ", err);
    }
};
    return (
        <div className="content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ width: '400px', height: '400px' }}>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">Login</CardTitle>
                    </CardHeader>
                    <CardBody>
                        {infoMessage && <div className="alert alert-info" role="alert">{infoMessage}</div>}
                        {error && <div className="alert alert-danger" role="alert">{error}</div>}
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label for="username">Username</Label>
                                <Input
                                    value={username}
                                    type="text"
                                    placeholder="Enter username"
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </FormGroup>
                            <Button type="submit" color="primary" className="btn-block">Login</Button>
                            {error && <div className="alert alert-danger" role="alert">{error}</div>}
                            {!resendDisabled && (
                                <div className="text-center mt-3">
                                    <Button onClick={handleResendVerification} color="link">Resend Verification Email</Button>
                                </div>
                            )}
                            <div className="text-center mt-3">
                                <Link to="/register">Need an account? Register</Link>
                            </div>
                            {/* <div className="text-center mt-2">
                                <Link to="/don'thave">Generate New Keys</Link>
                            </div> */}
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default Login;
