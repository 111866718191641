import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext.js';
import axios from 'axios';
import { Tooltip } from "reactstrap";
import { FaUserCircle , FaInfoCircle } from 'react-icons/fa';
import { FaPlus, FaUserMinus } from 'react-icons/fa';


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  Label,
} from "reactstrap";



function Tables() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);
  const { axiosInstance } = useContext(AuthContext);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState(null);

  const [showCreateGroupForm, setShowCreateGroupForm] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [activeGroupId, setActiveGroupId] = useState(null);
  const [showExpenseForm, setShowExpenseForm] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedGroupDetails, setSelectedGroupDetails] = useState(null);
  const [invitationCodesInput, setInvitationCodesInput] = useState("");
  const [currentUserRole, setCurrentUserRole] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [expenseData, setExpenseData] = useState({
    title: '',
    date: '',
    amount: '',
    desc: '',
    to: [],
  });
  const [editingExpenseId, setEditingExpenseId] = useState(null);

  const [groupExpenses, setGroupExpenses] = useState([]);

  const [showInviteForm, setShowInviteForm] = useState(false);
  const [memberUUID, setMemberUUID] = useState('');
  const [uuidError, setUuidError] = useState('');

  const toggleExpenseForm = () => setShowExpenseForm(!showExpenseForm);

  const handleCreateGroup = () => {
    // Toggle the visibility of the create group form
    setShowCreateGroupForm(!showCreateGroupForm);
  };  
  const fetchGroupDetails = async (groupId) => {
    try {
      const response = await axiosInstance.get(`/groups/${groupId}`);
      setSelectedGroupDetails(response.data);
    } catch (error) {
      console.error('Error fetching group details:', error);
      setSelectedGroupDetails(null);
      // Optionally, handle error state (e.g., show error message)
    }
  };
  // Handle clicking on a group
  const handleGroupClick = (groupId) => {
    setInfoMessage(null);
    setError(null)
    setActiveGroupId(groupId);
    fetchGroupDetails(groupId);
    const selectedGroup = groups.find(group => group.id === groupId);
    setCurrentUserRole(selectedGroup ? selectedGroup.user_role : '');
    // fetch expenses : 
    fetchExpenses(groupId);
  };
  // Additional state to track the currently active group for member management

  
  const handleKickMember = async (memberId) => {
    try {
      const response = await axiosInstance.delete(`groups/${activeGroupId}/kick/${memberId}`);
  
      if (response.status === 200) {
        setInfoMessage(response.data.message);
        setError(null)
        fetchGroupDetails(activeGroupId);
      } else {
        setError(response.data.error || 'Failed to kick member.');
      }
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred while trying to kick the member.');
      // Consider logging the error for debugging
    }
  };



    const toggleInviteForm = () => {
      setShowInviteForm(!showInviteForm);
      setUuidError('');
    };

    const handleUuidChange = (e) => {
      const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[4][0-9a-fA-F]{3}-[89ABab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
      const value = e.target.value;
      setMemberUUID(value);
      setUuidError(uuidRegex.test(value) ? '' : 'Invalid UUID format');
    };
     
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      if (name === "to") {
        // For a multi-select, extract all selected options
        const selectedOptions = Array.from(e.target.options)
                                     .filter(option => option.selected)
                                     .map(option => option.value);
        setExpenseData({ ...expenseData, to: selectedOptions });
      } else {
        // For other inputs
        setExpenseData({ ...expenseData, [name]: value });
      }
    };
  
  const renderExpenseForm = () => {
    const group_id = selectedGroupDetails.id;
    const currentUserId = user?.id; // Assuming 'user' is the current user's object

    // Prepare the options for the 'To' field, excluding the current user
    let membersOptions = selectedGroupDetails.members
        .filter(member => member.user.id !== currentUserId)
        .map(member => ({
            value: member.user.id,
            label: `${member.user.first_name} ${member.user.last_name}`
        }));

    // Include the group owner if they are not the current user and not already in the members list
    if (selectedGroupDetails.owner_details.id !== currentUserId && 
        !selectedGroupDetails.members.some(member => member.user.id === selectedGroupDetails.owner_details.id)) {
        membersOptions.push({
            value: selectedGroupDetails.owner_details.id,
            label: `${selectedGroupDetails.owner_details.first_name} ${selectedGroupDetails.owner_details.last_name}`
        });
    }
  
    return (
   
        <CardBody>
       <Form onSubmit={(e) => {
          e.preventDefault();
          submitExpenseForm(expenseData,group_id);
        }}>
       {    error && <div className="alert alert-danger">{error}</div>}

        <FormGroup>
          <Label for="title">Title</Label>
          <Input 
          type="text" 
          name="title" 
          id="title" 
          value={expenseData.title}
          onChange={handleInputChange} 
          required 
        />
        </FormGroup>
  
        <FormGroup>
          <Label for="date">Date</Label>
          <Input 
          type="date" 
          name="date" 
          id="date" 
          value={expenseData.date}
          onChange={handleInputChange} 
          required 
        />
        </FormGroup>
  
        <FormGroup>
            <Label for="amount">Amount</Label>
            <Input 
          type="number" 
          name="amount" 
          id="amount" 
          min="0.01"
          step="0.01"
          value={expenseData.amount}
          onChange={handleInputChange} 
          required 
        />
        </FormGroup>
          
        <FormGroup>
          <Label for="desc">Description (Optional)</Label>
          <Input 
          type="textarea" 
          name="desc" 
          id="desc" 
          value={expenseData.desc}
          onChange={handleInputChange} 
           
        />
        </FormGroup>
        
        <FormGroup>
          <Label for="to">To</Label>
          <Input 
            type="select" 
            name="to" 
            id="to" 
            multiple 
            value={expenseData.to}
            onChange={handleInputChange}
            required
          >
            {membersOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
        </FormGroup>
        <Button type="submit" color="primary" onClick={submitExpenseForm}>
          {editingExpenseId ? 'Edit Expense' : 'Submit Expense'}
        </Button>
      </Form>
      </CardBody>
  
    );
  };

  const submitGroupForm = async (e) => {
    e.preventDefault();
    setInfoMessage(null);
    setError(null)
    try {
      const invitationCodes = invitationCodesInput.split(',').map(code => code.trim());
      const groupData = {
        name: groupName,
        invitation_codes: invitationCodes, // Ensure this matches the field name expected by the Django view
      };
      const response = await axiosInstance.post('/groups/', groupData);
  
      if (response.status === 201) {
        fetchGroups(); // Refresh the group list
        setShowCreateGroupForm(false); // Hide the form upon success
        setError(null); // Clear any existing errors
      }
    } catch (error) {
      if (error.response && error.response.data.invalid_invitation_codes) {
        // Set the error message with the invalid codes
        setError(`The following invitation codes are invalid: ${error.response.data.invalid_invitation_codes.join(', ')}`);
        fetchGroups();
      } else {
        // Set a general error message
        setError('Error creating group. Please try again.');
      }
    }
  };
  
  const submitInviteForm = async (e) => {
    e.preventDefault();  // Prevent default form submission behavior
  
    try {
      // Assuming `uuid` is the state variable holding the invitee's UUID
      const response = await axiosInstance.post(`groups/${activeGroupId}/invite/`, { invitation_code: memberUUID });
  
      if (response.status === 201) {
        // Update UI to reflect success
        fetchGroupDetails(activeGroupId);
        setInfoMessage("Member invited successfully! ")
        setError(null)
      }
    } catch (error) {
      if (error.response) {

        setError(`Error: ${error.response.data.error || 'Failed to invite member.'}`);
      } else if (error.request) {
        setError('No response from the server. Please try again later.');
      } else {
        setError('Error: Could not process the request.');
      }
    }
  };

  const submitExpenseForm = async (expenseData,group_id) => {
    setInfoMessage(null);
    setError(null)
    try {
      let response;
  
      if (editingExpenseId) {
        // Editing an existing expense
        response = await axiosInstance.put(`groups/${group_id}/expenses/${editingExpenseId}/edit/`, expenseData);
  
        if (response.status === 200) {
          setInfoMessage('')
          setInfoMessage('Expense updated successfully');
          setError(null);
          fetchGroups()
          fetchGroupDetails(group_id);
          fetchExpenses(group_id);
          
          // Additional logic after successful expense update
        }
      } else {
        // Creating a new expense
        response = await axiosInstance.post(`groups/${group_id}/expenses/create/`, expenseData);
  
        if (response.status === 201) {
          setInfoMessage('')
          setInfoMessage('Expense created successfully');
          setError(null);
          fetchGroups()
          fetchGroupDetails(group_id);
          fetchExpenses(group_id);
          // Additional logic after successful expense creation
        }
      }
  
      // Common logic for both create and update
      if (response.status === 200 || response.status === 201) {
        // Reset form and state
        setExpenseData({ title: '', date: '', amount: '', desc: '', to: [] });
        setEditingExpenseId(null);
        setShowExpenseForm(false);
        fetchExpenses(group_id); // Refresh the expenses list
      }
    } catch (error) {
      if (error.response) {
        setError(`Error: ${error.response.data.error || 'Failed to process the expense.'}`);
      } else {
        setError('Error processing expense. Please try again.');
      }
    }
  };




  

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
          const userInfoResponse = await axios.get(`${API_BASE_URL}/login/info`, { withCredentials: true });
          console.log('doing check auth status ', userInfoResponse.status);
          if (userInfoResponse.status === 200) {
              setUser(userInfoResponse.data);
              navigate('/user/dashboard');
          }
      } catch (error) {
          console.error('Error checking auth status:', error);
          navigate('/login');
      }
  };
    if (!user) { // Checks if user is not set
        console.log("doing checkAuthStatus");
        checkAuthStatus();
    }
    fetchGroups();
  }, [user]);

  const fetchGroups = async () => {
    try {
      const response = await axiosInstance.get('groups/');
      setGroups(response.data); // Update the state with the fetched groups
    } catch (error) {
      console.error('Error fetching groups:', error);
      // Handle errors (e.g., redirect to login if unauthorized)
    }
  };

  const fetchExpenses = async (groupId) => {
    setInfoMessage(null);
    setError(null)
    try {
      const response = await axiosInstance.get(`/groups/${groupId}/expenses/`);
      if (response.status === 200) {
        setGroupExpenses(response.data); // Update state with fetched expenses
      }
    } catch (error) {
      console.error('Error fetching expenses:', error);
      // Handle errors, e.g., by setting an error state
    }
  };
  const StyledHr = () => (
    <hr style={{ border: '1px solid #ddd', margin: '30px 0' }} />
  );
  const handleEditExpense = (expenseId) => {
    const expenseToEdit = groupExpenses.find(expense => expense.id === expenseId);
    console.log("Editing Expense:", expenseToEdit)
    setExpenseData({
      title: expenseToEdit.title,
      date: expenseToEdit.date,
      amount: expenseToEdit.amount,
      desc: expenseToEdit.desc,
      to: expenseToEdit.affected_members,
    });
    setEditingExpenseId(expenseId);
    
  };
  
  const handleRemoveExpense = async (activeGroupId,expenseId) => {
    const expenseToRemove = groupExpenses.find(expense => expense.id === expenseId);
    // Check if the current user is the owner of the expense
    if (expenseToRemove && user?.username === expenseToRemove.encoded_by) {
      try {
        const response = await axiosInstance.delete(`/groups/${activeGroupId}/expenses/${expenseId}/delete/`);
  
        if (response.status === 200) {
          setInfoMessage('Expense successfully removed');
          setError(null)
          fetchGroupDetails(activeGroupId);
          fetchExpenses(activeGroupId); // Refresh the expenses list after removal
        }
      } catch (error) {
        setError(`Error: ${error.response?.data?.error || 'Failed to remove expense.'}`);
      }
    } else {
      setError('You are not authorized to remove this expense.');
    }
  };
  return (user ? (
<div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6" xs="12">
                    <CardTitle tag="h4">Groups</CardTitle>
                  </Col>
                  <Col md="6" xs="12" className="text-right">
                    <Button color="info" onClick={handleCreateGroup}>
                      Create Group
                    </Button>
                  </Col>
                  
                </Row>
              </CardHeader>
              <CardBody>
                {/* Conditionally render the form for creating a new group */}
                {showCreateGroupForm && (
                  <Form>
             {    error && <div className="alert alert-danger">{error}</div>}

                    <FormGroup>
                      <Label for="groupName">Group Name</Label>
                      <Input
                        type="text"
                        name="name"
                        id="groupName"
                        placeholder="Enter group name" 
                        onChange={(e) => setGroupName(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="invitationCodes">Invite Members</Label>
                        <Input
                          type="text"
                          id="invitationCodes"
                          placeholder="Enter invitation codes separated by commas"
                          onChange={(e) => setInvitationCodesInput(e.target.value)}
                        />
                    </FormGroup>
                    <Button color="primary" onClick={submitGroupForm}>
                      Submit Group
                    </Button>
                  </Form>
                )}
                   <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      
                      <th>Status</th>
                      <th>Balance</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                        {groups.map((group, index) => (
                          <tr 
                            key={group.id}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleGroupClick(group.id)}
                            onMouseEnter={() => setTooltipOpen(index)}
                            onMouseLeave={() => setTooltipOpen(null)}
                          >
                            <td>{group.name}</td>
                            <td>{group.user_role}</td>
                            <td>{group.user_balance.toFixed(2)}</td>
                            <td>
                              <FaInfoCircle id={`tooltip-${index}`} />
                              <Tooltip
                                placement="right"
                                isOpen={tooltipOpen === index}
                                target={`tooltip-${index}`}
                                toggle={() => toggleTooltip(index)}
                              >
                                Click for more details
                              </Tooltip>
                            </td>
                          </tr>
                        ))}
               </tbody>
          </Table>
          <StyledHr />
          {infoMessage && <div className="alert alert-info" role="alert">{infoMessage}</div>}
          {    error && <div className="alert alert-danger">{error} </div>}
          {selectedGroupDetails && (
                <>
                  <Row>
                      <Col md={currentUserRole === "Owner" ? "4" : "6"} xs="12">
                        <CardTitle tag="h4">{selectedGroupDetails.name}</CardTitle>
                      </Col>
                      {currentUserRole === "Owner" && (
                        <Col md="4" xs="12" className="text-center">
                          <Button color="info" onClick={toggleInviteForm}>
                            {showInviteForm ? 'Hide Invite Form' : 'Invite Members'}
                          </Button>
                        </Col>
                      )}
                      <Col md={currentUserRole === "Owner" ? "4" : "6"} xs="12" className="text-right">
                        <Button color="info" onClick={toggleExpenseForm}>
                          {showExpenseForm ? 'Hide Expense Form' : 'Create Expense'}
                        </Button>
                      </Col>
                  </Row>

                      {showInviteForm && (
                        
                        <Row>
                          {/* {    error && <div className="alert alert-danger">{error}</div>}
                          {infoMessage && <div className="alert alert-info" role="alert">{infoMessage}</div>} */}

                          <Col md="12">
                            <form>
                              <div className="form-group">
                                <label htmlFor="memberUUID">Member Invitation Code:</label>
                                <input
                                  type="text"
                                  className={`form-control ${uuidError ? 'is-invalid' : ''}`}
                                  id="memberUUID"
                                  value={memberUUID}
                                  onChange={handleUuidChange}
                                  placeholder="Enter member UUID"
                                />
                                {uuidError && <div className="invalid-feedback">{uuidError}</div>}
                                {!uuidError && memberUUID && (
                                  <button type="submit"  className="btn btn-primary" onClick={submitInviteForm} >Invite Member</button>
                                )}
                              </div>
                              {/* Submit button and other form elements */}
                            </form>
                          </Col>
                        </Row>
                      )}
                  {showExpenseForm && (
                    <Row>
                      {renderExpenseForm()}
                    </Row>
                  )}
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th className="text-right">Balance</th>
                        {currentUserRole === "Owner" && (
                        <th className="text-right">Actions</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">1</td>
                        <td>{selectedGroupDetails.owner_details.first_name} {selectedGroupDetails.owner_details.last_name}</td>
                        <td>Owner</td>
                        <td className="text-right">{selectedGroupDetails.balance_owner}</td>
                        <td className="text-right">
                          {/* Owner actions */}
                        </td>
                      </tr>
                      {selectedGroupDetails.members.map((member, index) => (
                        <tr key={member.user.id}>
                          <td className="text-center">{index + 2}</td>
                          <td>{member.user.first_name} {member.user.last_name}</td>
                          <td>Member</td>
                          <td className="text-right">{member.balance}</td>
                          <td className="text-right">
                            {/* Member actions */}

                            {currentUserRole === "Owner" && (
                                <Button className="btn-icon" color="danger" size="sm" onClick={() => handleKickMember(member.user.id)}>
                                  <i className="fa fa-times" />
                                </Button>
                              )}
                            
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Table responsive>
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th>Title</th>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Encoded By</th>
                          <th>Description</th>
                          <th>Affected Members</th>
                          <th></th>
                          {/* Other columns as needed */}
                        </tr>
                      </thead>
                      <tbody>
                      {groupExpenses.map((expense, index) => (
                         <>
                            <tr key={expense.id}>
                            <td className="text-center">{index + 1}</td>
                                <td>{expense.title}</td>
                                <td>{expense.date}</td>
                                <td>{expense.amount}</td>
                                <td>{expense.encoded_by}</td>
                                <td>{expense.desc}</td>
                                <td>
                                  {expense.affected_members && expense.affected_members.join(', ')}
                                </td>
                                <td>
                                {user?.username === expense.encoded_by && (
                                  <Button color="info" size="sm" onClick={() => handleEditExpense(expense.id)}>
                                    Edit
                                  </Button>
                                  
                                )}
                              </td>
                              <td>
                                {user?.username === expense.encoded_by && (
                                  <Button className="btn-icon" color="danger" size="sm" onClick={() => handleRemoveExpense(activeGroupId,expense.id)}>
                                  <i className="fa fa-times" />
                                </Button>
                                )}
                              </td>
                            </tr>
                            {editingExpenseId === expense.id && (
                              <tr>
                                <td colSpan="8">
                                  {renderExpenseForm()}
                                </td>
                              </tr>
                            )}
                            </>
                          ))}
                      </tbody>
                    </Table>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  ) : (
    <div>
      <p>You are not logged in, redirecting...</p>
    </div>
  ));
}

export default Tables;